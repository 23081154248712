import { IDrugSearchMatch } from '@/interfaces/IDrug';
import SearchableEntity from './SearchableEntity';

export default class SearchableDrug extends SearchableEntity {
  entityType = 'Drug';

  constructor(dsm: IDrugSearchMatch) {
    super(dsm.drug.chembl_id, dsm.matching_drug, dsm);
  }

  get url(): string {
    return `/drug/${this.identifier}`;
  }
}
