
























import Vue from 'vue';
import axios from 'axios';

import { IDrug2GeneSearchResult } from '@/interfaces/ISearchResult';
import { IDrug } from '@/interfaces/IDrug';
import GeneHit from './GeneHit.vue';

export default Vue.extend({
  components: {
    GeneHit,
  },
  data() {
    return {
      drug: this.$props.entity,
      searchResults: null as IDrug2GeneSearchResult[] | null,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/drugs/${this.drug.chembl_id}/genes/`;
    },
    getPathogenicityEvidenceUrl(entrezId: number): string {
      return `${this.$apiPrefix}/drugs/${this.drug.chembl_id}/genes/${entrezId}/pathogenicity/`;
    },
    getTreatabilityEvidenceUrl(entrezId: number): string {
      return `${this.$apiPrefix}/drugs/${this.drug.chembl_id}/genes/${entrezId}/treatability/`;
    },
  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IDrug,
  },
});
