export interface ISidepanelStoreState {
  isOpen: boolean;
  component: Vue.Component | null;
  // eslint-disable-next-line
  componentProps: Record<string, any>;
  contentIdentifier: string | null;
}

interface ISidepanelStorePayload {
  component: Vue.Component;
  // eslint-disable-next-line
  props: Record<string, any>;
  contentIdentifier: string;
}

export default {
  // Makes mutations getters, mapgetters and other attributes available under
  // the sidepanel namespace. This allows for less redundancy. For example,
  // `this.$store.commit('openSidepanel')` -> `this.$store.commit('sidepanel/open')`
  namespaced: true,
  state: {
    isOpen: false,
    component: null,
    componentProps: {},
    // An attribute so that we can know who claimed sidepanel. Assumes uniqueness
    // across components, acts as a pseudo component instance id.
    contentIdentifier: null,
  },
  mutations: {
    open(state: ISidepanelStoreState, payload: ISidepanelStorePayload): void {
      state.isOpen = true;
      state.component = payload.component;
      state.componentProps = payload.props;
      state.contentIdentifier = payload.contentIdentifier;
    },
    close(state: ISidepanelStoreState): void {
      state.isOpen = false;
      state.component = null;
      state.componentProps = {};
      state.contentIdentifier = null;
    },
  },
  getters: {
    contentIdentifier:
    (state: ISidepanelStoreState): string | null => (state.contentIdentifier),
  },
};
