import { IUser } from '@/interfaces/IUser';

export interface IMainStoreState {
  selectedSearchableEntity: null;
  monitorErrors: boolean;
  user?: IUser;
}

interface IMainStorePayload {
  selectedSearchableEntity: null;
  user?: IUser;
}

export default {
  state: {
    selectedSearchableEntity: null,
    monitorErrors: true,
    user: undefined,
  },
  mutations: {
    setEntity(state: IMainStoreState, payload: IMainStorePayload): void {
      state.selectedSearchableEntity = payload.selectedSearchableEntity;
    },
    pauseMonitoring(state: IMainStoreState): void {
      state.monitorErrors = false;
    },
    resumeMonitoring(state: IMainStoreState): void {
      state.monitorErrors = true;
    },
    setUser(state: IMainStoreState, payload: IMainStorePayload): void {
      state.user = payload.user;
    },
  },
};
