/* eslint-disable */

import BasePlot from './base.js';
// import * as d3 from "https://cdn.skypack.dev/d3@7"; // browser use
import * as d3 from 'd3'; // webpack use

export default class HorizontalZoomPlot extends BasePlot {

  render() {
    this.createSVG();
    this.createClipPath();

    this.createXScale();
    this.createZoomXScale();

    this.createXAxis();
    this.enableXAxis();

    this.createZoom();
    this.enableZoom();
  }

  createZoomXScale() {
    // identical to createXScale, but this scale gets modified in each zoom
    // event. All zoom-aware coordinate assignments must pass through *this*
    // scale and not through xScale.
    this.zoomXScale = d3.scaleLinear()
      .domain(this.xDomain)
      .range([this.opts.margin.left, this.opts.width - this.opts.margin.right]);
  }

  createXAxis() {
    this.xAxis = d3.axisBottom(this.zoomXScale);
  }

  createClipPath() {
    // we use clip paths to avoid plots overflowing into the margins that are
    // reserved for axes, labels, etc. See docs:
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Element/clipPath
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/clip-path
    const clipId = `${this.id}-clip`;

    this.root.attr('clip-path', `url(#${clipId})`);

    this.clipPath = this.svg.append('clipPath').attr('id', clipId);

    // for the plot body that's not allowed to overflow its specified margin,
    // even though it *could* be drawn within the <svg>
    this.clipPath
      .append('rect')
      .attr('x', this.opts.margin.left)
      .attr('y', 0)
      .attr('width', this.opts.width - this.opts.margin.right - this.opts.margin.left)
      .attr('height', this.opts.height);

    // for the axis and its label that are allowed more width than the plot body
    this.clipPath
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.opts.width)
      .attr('height', 20);
  }

  createZoom() {
    this.zoom = d3.zoom()
      .scaleExtent([1, 150]) // how much can the user zoom?
      .translateExtent([
        [this.opts.margin.left, 0],
        [this.opts.width - this.opts.margin.right, this.opts.height],
      ])
      .extent(([
        [this.opts.margin.left, 0],
        [this.opts.width - this.opts.margin.right, this.opts.height],
      ]))

    this.zoom.on('zoom', this.onZoom.bind(this));
  }

  enableZoom() {
    this.svg.call(this.zoom);
  }

  onZoom(event) {
    const newXScale = event.transform.rescaleX(this.xScale);

    this.zoomXScale.domain(newXScale.domain());
    this.setZoomAwareAttrs(newXScale);

    this.root.select('.axis--x').call(this.xAxis, newXScale); // see enableXAxis()
  }

  setZoomAwareAttrs(newXScale) {
    console.warn(`Unimplemented method setZoomAwareAttrs() for ${this.constructor.name}`);
  }
}
