





































































import Vue from 'vue';
import axios from 'axios';
import { IDiseaseSearchMatch } from '@/interfaces/IDisease';
import { IDrugSearchMatch } from '@/interfaces/IDrug';
import { IGeneSearchMatch } from '@/interfaces/IGene';
import { IVariantSearchMatch } from '@/interfaces/IVariant';
import { ISearchableEntityData } from '@/interfaces/ISearchableEntityData';
import SearchableEntity from '@/models/SearchableEntity';
import SearchableDisease from '@/models/SearchableDisease';
import SearchableGene from '@/models/SearchableGene';
import SearchableDrug from '@/models/SearchableDrug';
import SearchableVariant from '@/models/SearchableVariant';

export default Vue.extend({
  name: 'AutoComplete',
  computed: {
    selectedEntity() {
      return this.$store.state.main.selectedSearchableEntity;
    },
    isComputingQuery() {
      // Consider the time between genes being updated and an inbound click
      // If the component just received some new genes and the input is updated
      // shortly after, we will see `No results found` for a split second
      return this.loading || this.searchableEntityMatches.length;
    },
    emptyResultsMessage() {
      if (this.isComputingQuery || this.search.length < this.minQueryLen) {
        return 'Search for a human gene, condition, or targeted drug';
      }
      return 'No results found!';
    },
    // In addition to regular search results, we must also add the selected
    // results to properly render the chips
    entityItems(): SearchableEntity[] {
      return this.selectedEntities.concat(this.searchableEntityMatches);
    },
    // Since this computed property is used as a value of `v-model` we need to
    // specify a getter and a setter for the html <input> to set the corresponding
    // proprty. Since `selectedEntities` is our source of truth, the setter for
    // this property is effectively stale, but the compiler still requires it.
    selectedEntityIds: {
      get(): string[] {
        return this.selectedEntities.map((se: SearchableEntity) => se.searchableEntityIdentifier);
      },
      set(entitiyIds: string[]) {
        return entitiyIds;
      },
    },
  },
  data: () => ({
    // Entities which are matched by the current query in the search box
    searchableEntityMatches: [] as SearchableEntity[],
    // Entities which are selected and rendered as chips
    selectedEntities: [] as SearchableEntity[],
    debounceId: 0,
    loading: false,
    minQueryLen: 2,
    search: '',
  }),
  watch: {
    async search(val: string) {
      if (!val) {
        // Don't let the html input element set this to null
        this.search = val || '';
        this.searchableEntityMatches = [];
        return;
      }
      this.debounce(() => { this.entitySearch(val); });
    },
    selectedEntity() {
      this.selectedEntities = [this.selectedEntity];
    },
  },
  methods: {
    navigateToEntity() {
      // FIXME: Redirect to first selected entity for now
      this.$router.push(this.selectedEntities[0].url).catch((error: Error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
        // VAutocomplete won't update its selectedItems if we are in search mode.
        // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VAutocomplete/VAutocomplete.ts#L389
        this.search = '';
      });
    },
    // Results fetched from backend are already filtered. Tell vuetify not to
    // apply its own filtering logic
    filter: () => true,
    debounce(debounced: () => void) {
      this.loading = true;
      // cancel pending call
      clearTimeout(this.debounceId);

      // delay new call 500ms
      this.debounceId = setTimeout(() => {
        debounced();
      }, 500);
    },
    async entitySearch(query: string) {
      const response = await axios.get(`${this.$apiPrefix}/usearch/`, {
        params: { query },
      });
      this.searchableEntityMatches = this.initializeEntities(response.data);
      this.loading = false;
    },
    updateEntities(entityId: string | null): void {
      this.selectedEntities = [];
      if (entityId) {
        this.selectedEntities.push(this.entityItems.filter(
          (se: SearchableEntity) => se.searchableEntityIdentifier === entityId,
        )[0]);
        this.navigateToEntity();
      }
    },
    removeEntity(removedId: string): void {
      this.selectedEntities = this.selectedEntities.filter(
        (se: SearchableEntity) => se.searchableEntityIdentifier !== removedId,
      );
    },
    initializeEntities(entityData: (ISearchableEntityData)[]): SearchableEntity[] {
      return entityData.map((datum: ISearchableEntityData) => (
        this.initializeEntity(datum)
      ));
    },
    /*
     * The following function must infer which type of entity we are dealing
     * with. We should avoid these types of scenario. This is the only scenario
     * when the FE determines which object it is dealing with:
     * 1. `initializeEntry`: BE sends a list of entities of different types.
     *     FE must determine which entity type we are dealing with to use the
     *     correct keys and render the entity properly.
     */
    initializeEntity(entityData: ISearchableEntityData): SearchableEntity {
      if (Object.keys(entityData).includes('disease')) {
        const match = entityData as IDiseaseSearchMatch;
        return new SearchableDisease(match);
      }
      if (Object.keys(entityData).includes('drug')) {
        const match = entityData as IDrugSearchMatch;
        return new SearchableDrug(match);
      }
      if (Object.keys(entityData).includes('cpra')) {
        const match = entityData as IVariantSearchMatch;
        return new SearchableVariant(match);
      }
      const gsm = entityData as IGeneSearchMatch;
      return new SearchableGene(gsm);
    },
  },
});
