




























































import Vue from 'vue';

import { IDiseaseSearchResult } from '@/interfaces/ISearchResult';
import RelationshipInstanceSummary from './RelationshipInstanceSummary.vue';
import PathogenicitySummary from './PathogenicitySummary.vue';
import TreatabilitySummary from './TreatabilitySummary.vue';

export default Vue.extend({
  components: {
    RelationshipInstanceSummary,
    PathogenicitySummary,
    TreatabilitySummary,
  },
  computed: {
    chips(): string[] {
      const chips = [
        this.searchResult.pathogenicity?.variant_name,
        this.searchResult.treatability?.variant_name,
      ];
      // FIXME: no instances of two chips found in db so far
      // Remove duplicate chips, remove undefined chips
      return Array.from(new Set(chips.filter((chip: string|undefined) => !!chip))) as string[];
    },
  },
  props: {
    searchResult: Object as () => IDiseaseSearchResult,
    entityName: String,
    pathogenicityEvidenceUrl: String,
    treatabilityEvidenceUrl: String,
  },
});

