import Vue from 'vue';
import Vuex from 'vuex';
import VueMeta from 'vue-meta';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import styles from '@/assets/scss/exports.scss';
import App from './App.vue';
import { ISgxWindow } from './interfaces';
import SgxMixpanel from './mixpanel';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store/index';
import '@babel/polyfill';

Vue.use(VueMeta);
Vue.use(Vuex);

Vue.config.productionTip = false;

// https://vuejs.org/v2/cookbook/adding-instance-properties.html
Vue.prototype.$mockPrefix = '/api-mock';
Vue.prototype.$sgxStyles = styles;

(async () => {
  if ((window as ISgxWindow).prerender) {
    Vue.prototype.$apiPrefix = '/api-proxy';
    Vue.prototype.$trackEvent = () => null;
  } else {
    const response = await axios.get('/frontend-data/');
    const { sentry } = response.data;
    if (sentry.send_events) {
      Sentry.init({
        Vue,
        ...sentry.sentry_data,
        integrations: [
          new Integrations.BrowserTracing({
            // eslint-disable-next-line
            routingInstrumentation: Sentry.vueRouterInstrumentation(router as any),
            tracingOrigins: [],
          }),
        ],
        beforeSend(event) {
          if (store.state.main.monitorErrors) {
            return event;
          }
          return null;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // tracesSampleRate: 1.0,
      });
      Sentry.setTag('seeq_api_instance', sentry.seeq_api_instance);
    }

    const sgxMixpanel = new SgxMixpanel(response.data.mixpanel);
    Vue.prototype.$trackEvent = sgxMixpanel.trackEvent.bind(sgxMixpanel);

    Vue.prototype.$apiPrefix = response.data.seeq_api_prefix;
    Vue.prototype.$providerLoginUrls = {
      google: response.data.google_account_login,
    };
    store.state.main.user = response.data.user;
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
    mounted() {
      setTimeout(() => {
        document.dispatchEvent(new Event('vue-post-render'));
      }, 2500);
    },
  }).$mount('#app');
})();
