















































































































































































































































import Vue from 'vue';

export default Vue.extend({
  metaInfo() {
    return {
      title: 'SeeqVCF About',
      meta: [
        {
          name: 'description',
          content: 'Find out more about our innovative VCF analysis tool — '.concat(
            'client-side, no-installation and results in under thirty seconds.',
          ),
        },
      ],
    };
  },
});
