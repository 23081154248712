
























import Vue from 'vue';
import axios from 'axios';

import { IGene2DrugSearchResult } from '@/interfaces/ISearchResult';
import { IGene } from '@/interfaces/IGene';
import DrugHit from './DrugHit.vue';

export default Vue.extend({
  components: {
    DrugHit,
  },
  data() {
    return {
      gene: this.$props.entity,
      searchResults: null as IGene2DrugSearchResult[] | null,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/genes/${this.gene.entrez_id}/drugs/`;
    },
    getTreatabilityEvidenceUrl(chembl: string): string {
      return `${this.$apiPrefix}/genes/${this.gene.entrez_id}/drugs/${chembl}/treatability/`;
    },
  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IGene,
  },
});
