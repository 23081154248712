import GeneDiseaseHitList from '@/components/GeneDiseaseHitList.vue';
import GeneDrugHitList from '@/components/GeneDrugHitList.vue';
import GeneBrowser from '@/components/GeneBrowser.vue';
import JoinBeta from '@/components/JoinBeta.vue';

import Facet from '@/models/Facet';

export default [
  new Facet(
    'conditions',
    'Conditions',
    GeneDiseaseHitList,
  ),
  new Facet(
    'treatments',
    'Treatments',
    GeneDrugHitList,
  ),
  new Facet(
    'clinical-trials',
    'Clinical Trials',
    JoinBeta,
  ),
  new Facet(
    'pathways',
    'Pathways',
    JoinBeta,
  ),
  new Facet(
    'browser',
    'Gene Browser',
    GeneBrowser,
    'gene-browser-tab',
  ),
];
