























import Vue from 'vue';

import { ITreatability, TreatabilityColors } from '@/interfaces/ITreatability';
import RelationshipInstanceSummary from './RelationshipInstanceSummary.vue';
import TreatabilityEvidence from './TreatabilityEvidence.vue';

export default Vue.extend({
  data: () => ({
    colors: TreatabilityColors,
    evidenceComponent: TreatabilityEvidence,
  }),
  components: {
    RelationshipInstanceSummary,
  },
  computed: {
    evidenceTitle(): string {
      return `${this.entityName} ↔ ${this.hitName}`;
    },
    resultIdentifier(): string {
      let hitIdentifier = this.hitName;
      if (this.treatability?.variant_name) {
        hitIdentifier = `${hitIdentifier}-${this.treatability.variant_name}`;
      }
      return `${hitIdentifier}-treatability`;
    },
  },
  props: {
    hitName: String,
    entityName: String,
    evidenceUrl: String,
    treatability: Object as () => ITreatability | undefined,
  },
});
