

























import Vue from 'vue';
import axios from 'axios';

import { IDisease2GeneSearchResult } from '@/interfaces/ISearchResult';
import { IDisease } from '@/interfaces/IDisease';
import GeneHit from './GeneHit.vue';

export default Vue.extend({
  components: {
    GeneHit,
  },
  data() {
    return {
      disease: this.$props.entity,
      searchResults: null as IDisease2GeneSearchResult[] | null,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/diseases/${this.disease.cui}/genes/`;
    },
    getPathogenicityEvidenceUrl(entrezId: number): string {
      return `${this.$apiPrefix}/diseases/${this.disease.cui}/genes/${entrezId}/pathogenicity/`;
    },
    getTreatabilityEvidenceUrl(entrezId: number): string {
      return `${this.$apiPrefix}/diseases/${this.disease.cui}/genes/${entrezId}/treatability/`;
    },
  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IDisease,
  },
});
