
























import Vue from 'vue';
import axios from 'axios';

import { IDrug2GeneSearchResult } from '@/interfaces/ISearchResult';
import { IDrug } from '@/interfaces/IDrug';
import DiseaseHit from './DiseaseHit.vue';

export default Vue.extend({
  components: {
    DiseaseHit,
  },
  data() {
    return {
      drug: this.$props.entity,
      searchResults: null as IDrug2GeneSearchResult[] | null,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/drugs/${this.drug.chembl_id}/diseases/`;
    },
    getTreatabilityEvidenceUrl(cui: string): string {
      return `${this.$apiPrefix}/drugs/${this.drug.chembl_id}/diseases/${cui}/treatability/`;
    },
  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IDrug,
  },
});
