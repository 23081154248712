import Vue from 'vue';
import Vuex from 'vuex';

import MainStoreModule, { IMainStoreState } from './modules/main';
import SidepanelStoreModule, { ISidepanelStoreState } from './modules/sidepanel';

Vue.use(Vuex);

export interface IState {
  main: IMainStoreState;
  sidepanel: ISidepanelStoreState;
}

const store = new Vuex.Store<IState>({
  modules: {
    main: MainStoreModule,
    sidepanel: SidepanelStoreModule,
  },
});
export default store;
