










import Vue from 'vue';
import ServerMarkup from '../components/ServerMarkup.vue';

export default Vue.extend({
  components: {
    ServerMarkup,
  },
  metaInfo() {
    return {
      title: 'seeq.bio FAQ',
    };
  },
});
