import { IDiseaseSearchMatch } from '@/interfaces/IDisease';
import SearchableEntity from './SearchableEntity';

export default class SearchableDisease extends SearchableEntity {
  entityType = 'Disease';

  constructor(dsm: IDiseaseSearchMatch) {
    super(dsm.disease.cui, dsm.disease.name, dsm);
  }

  get url(): string {
    return `/condition/${this.identifier}`;
  }
}
