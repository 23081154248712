import { IDisease } from './IDisease';
import { IGene } from './IGene';
import { IVariant } from './IVariant';

/* eslint-disable camelcase */
type clinsig = 'pathogenic' | 'possibly_pathogenic';
type sourceType = 'ClinVar' | 'CIViC';

export interface IPathogenicityPublication {
  pmid: number;
  title: string;
  journal: string;
  year?: number;
  first_author?: string;
}

interface IClinvarCitation {
  publications: IPathogenicityPublication[];
  pmids: number[],
  text?: string,
}

interface IPathogenicitySummary {
  max_clinsig: clinsig,
  evidence_count: number,
  text: string,
}

export interface IPathogenicity {
  disease: IDisease;
  summary: IPathogenicitySummary;
  variant_name?: string;
}

export interface IPathogenicityEvidence {
  gene: IGene,
  disease: IDisease,
  variant?: IVariant,
  evidence_id: string,
  citation: IClinvarCitation,
  clinsig: clinsig,
  source: sourceType;
  source_id: string;
  source_version: string;
}

export enum PathogenicityColors {
  possibly_pathogenic = 'orange',
  pathogenic = 'red',
}
