/* eslint-disable */
// import * as d3 from "https://cdn.skypack.dev/d3@7"; // browser use
import * as d3 from 'd3'; // webpack use

export default class BasePlot {
  constructor(props) {
    this.id = props.id;
    this.data = props.data;

    this.opts = {
      // width and height only affect ratio, actual size is inherited from
      // parent div.
      width:  1000,
      height: 400,
      margin: {
        // margin affects xScale and how much width is *actually* used
        right:  120,
        left:   30,
      },
    };
  }

  render() {
    this.createSVG();

    this.createXScale();

    this.createXAxis();
    this.enableXAxis();
  }

  createSVG() {
    // Unless you have a good reason to, always append to the root <g> instead
    // of the <svg>.
    //
    // Some d3 behavior work on <g> elements and cannot be applied to the whole
    // <svg>, eg see clipPath in zoom.
    this.svg = d3.select(`#${this.id}`).append('svg')
      .attr('viewBox', `0, 0, ${this.opts.width}, ${this.opts.height}`);

    // the root group for all drawn elements
    this.root = this.svg.append('g');
  }

  get xDomain() {
    // xDomain needs to be a getter method instead of declared in subclasses
    // constructor since lots of base class logic that precedes subclass code
    // require it to function. You can only assume this.data is populated.
    throw new Error(`Unimplemented method xDomain() in ${this.constructor.name}`);
  }

  createXScale() {
    this.xScale = d3.scaleLinear()
      .domain(this.xDomain)
      .range([this.opts.margin.left, this.opts.width - this.opts.margin.right]);
  }

  createXAxis() {
    this.xAxis = d3.axisBottom(this.xScale);
  }

  enableXAxis() {
    this.root.append('g')
      .attr('class', 'axis axis--x')
      .call(this.xAxis);
  }
}
