import DiseaseGeneHitList from '@/components/DiseaseGeneHitList.vue';
import DiseaseDrugHitList from '@/components/DiseaseDrugHitList.vue';
import JoinBeta from '@/components/JoinBeta.vue';

import Facet from '@/models/Facet';

export default [
  new Facet(
    'treatments',
    'Treatments',
    DiseaseDrugHitList,
  ),
  new Facet(
    'genes',
    'Genes',
    DiseaseGeneHitList,
  ),
  new Facet(
    'clinical-trials',
    'Clinical Trials',
    JoinBeta,
  ),
];
