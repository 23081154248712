
























import Vue from 'vue';
import axios from 'axios';

import { IDisease2DrugSearchResult } from '@/interfaces/ISearchResult';
import { IDisease } from '@/interfaces/IDisease';
import DrugHit from './DrugHit.vue';

export default Vue.extend({
  components: {
    DrugHit,
  },
  data() {
    return {
      disease: this.$props.entity,
      searchResults: null as IDisease2DrugSearchResult[] | null,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/diseases/${this.disease.cui}/drugs/`;
    },
    getTreatabilityEvidenceUrl(chembl: string): string {
      return `${this.$apiPrefix}/diseases/${this.disease.cui}/drugs/${chembl}/treatability/`;
    },
  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IDisease,
  },
});
