


























import Vue from 'vue';
import axios from 'axios';

import { IDiseaseSearchResult } from '@/interfaces/ISearchResult';
import { IVariantInfo } from '@/interfaces/IVariant';
import DiseaseHit from './DiseaseHit.vue';

export default Vue.extend({
  components: {
    DiseaseHit,
  },
  data() {
    // FIXME refactor out into a variant model? see VariantDrugHitList
    const variant = this.$props.entity;
    variant.name = variant.aa_change || variant.nt_change;
    variant.full_name = `${variant.gene_symbol} ${variant.name}`;
    return {
      variant,
      // FIXME get correct type
      // eslint-disable-next-line
      searchResults: null as any,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/variants/${this.variant.cpra}/diseases/`;
    },
    getRelationshipEvidenceUrl(searchResult: IDiseaseSearchResult, rel: string) {
      const aaChange = searchResult[rel]?.variant_name;
      if (!aaChange) {
        return null;
      }
      const gene = this.variant.entrez_id;
      const { cui } = searchResult.hit;

      return `${this.$apiPrefix}/variants/${gene}/${aaChange}/diseases/${cui}/${rel}`;
    },
    getVariantName(searchResult: IDiseaseSearchResult) {
      return searchResult.pathogenicity?.variant_name || searchResult.treatability?.variant_name;
    },
  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IVariantInfo,
  },
});
