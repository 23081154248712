










import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  data: () => ({
    markup: '',
  }),
  props: {
    urlEndpoint: String,
  },
  async mounted() {
    const response = await axios.get(this.urlEndpoint);
    this.markup = response.data;
  },
  updated() {
    if (this.$route.hash) {
      // $route.hash is always given with the `#`
      const el = document.getElementById(this.$route.hash.replace('#', ''));
      if (el) {
        (el as HTMLElement).scrollIntoView();
      }
    }
  },
});
