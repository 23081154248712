























import Vue from 'vue';

import { IPathogenicity, PathogenicityColors } from '@/interfaces/IPathogenicity';
import RelationshipInstanceSummary from './RelationshipInstanceSummary.vue';
import PathogenicityEvidence from './PathogenicityEvidence.vue';

export default Vue.extend({
  data: () => ({
    colors: PathogenicityColors,
    evidenceComponent: PathogenicityEvidence,
  }),
  components: {
    RelationshipInstanceSummary,
  },
  computed: {
    evidenceTitle(): string {
      return `${this.entityName} ↔ ${this.hitName}`;
    },
    resultIdentifier(): string {
      let hitIdentifier = this.hitName;
      if (this.pathogenicity?.variant_name) {
        hitIdentifier = `${hitIdentifier}-${this.pathogenicity.variant_name}`;
      }
      return `${hitIdentifier}-pathogenicity`;
    },
  },
  props: {
    hitName: String,
    entityName: String,
    evidenceUrl: String,
    pathogenicity: Object as () => IPathogenicity | undefined,
  },
});
