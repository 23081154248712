import { IVariantSearchMatch } from '@/interfaces/IVariant';
import SearchableEntity from './SearchableEntity';

export default class SearchableGene extends SearchableEntity {
  entityType = 'Variant';

  constructor(vsm: IVariantSearchMatch) {
    super(String(vsm.cpra), vsm.matching_variant, vsm);
  }

  get url(): string {
    return `/variant/${this.identifier}`;
  }
}
