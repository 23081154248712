import { IDisease } from './IDisease';
import { IGene } from './IGene';
import { IDrug } from './IDrug';
import { IVariant } from './IVariant';

/* eslint-disable camelcase */
type clinsig = 'positive' | 'possibly_positive' | 'negative' | 'possibly_negative' |
               'interaction' | 'indication0' | 'indication1' | 'indication2' |
               'indication3' |'indication4';
type citationType = 'PubMed' | 'ASCO' | 'FDA' | 'ClinicalTrials' | 'ATC' | 'DailyMed';
type sourceType = 'CIViC' | 'DGI' | 'ChEMBL';

export interface ITreatabilityPublication {
  pmid: number;
  title: string;
  journal: string;
  year?: number;
  first_author?: string;
}

interface ITreatabilityCitation {
  publications: ITreatabilityPublication[];
  citation_type: citationType,
  citation_ids: string[],
  text?: string,
}

interface ITreatabilitySummary {
  max_clinsig: clinsig,
  evidence_count: number,
  text: string,
}

export interface ITreatability {
  drug: IDrug;
  summary: ITreatabilitySummary;
  variant_name?: string;
}

export interface ITreatabilityEvidence {
  gene?: IGene,
  disease?: IDisease,
  variant?: IVariant,
  drug: IDrug,
  citation: ITreatabilityCitation;
  clinsig: clinsig,
  source_id: string;
  source: sourceType,
  source_version: string,
}

export enum TreatabilityColors {
  positive = 'pistachio',
  possibly_positive = 'blue',
  interaction = 'nosig',
  possibly_negative = 'orange',
  negative = 'red',
  indication0 = 'nosig',
  indication1 = 'blue',
  indication2 = 'blue',
  indication3 = 'blue',
  indication4 = 'pistachio',
}
