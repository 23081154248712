import VariantDiseaseHitList from '@/components/VariantDiseaseHitList.vue';
import VariantDrugHitList from '@/components/VariantDrugHitList.vue';
import GeneBrowser from '@/components/GeneBrowser.vue';
import JoinBeta from '@/components/JoinBeta.vue';

import Facet from '@/models/Facet';

export default [
  new Facet(
    'conditions',
    'Conditions',
    VariantDiseaseHitList,
  ),
  new Facet(
    'treatments',
    'Treatments',
    VariantDrugHitList,
  ),
  new Facet(
    'clinical-trials',
    'Clinical Trials',
    JoinBeta,
  ),
  new Facet(
    'browser',
    'Gene Browser',
    GeneBrowser,
    'gene-browser-tab',
  ),
];
