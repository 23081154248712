

























import Vue from 'vue';
import axios from 'axios';

import { IVariantInfo } from '@/interfaces/IVariant';
import { IDrugSearchResult } from '@/interfaces/ISearchResult';
import DrugHit from './DrugHit.vue';

export default Vue.extend({
  components: {
    DrugHit,
  },
  data() {
    const variant = this.$props.entity;
    variant.name = variant.aa_change || variant.nt_change;
    variant.full_name = `${variant.gene_symbol} ${variant.name}`;
    return {
      variant,
      // FIXME get correct type
      // eslint-disable-next-line
      searchResults: null as any,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/variants/${this.variant.cpra}/drugs/`;
    },
    getTreatabilityEvidenceUrl(searchResult: IDrugSearchResult) {
      const aaChange = searchResult.treatability.variant_name;
      if (!aaChange) {
        return null;
      }
      const gene = this.variant.entrez_id;
      const chemblId = searchResult.hit.chembl_id;

      return `${this.$apiPrefix}/variants/${gene}/${aaChange}/drugs/${chemblId}/treatability`;
    },

  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IVariantInfo,
  },
});
