

























import Vue from 'vue';
import axios from 'axios';

import { IGene2DiseaseSearchResult } from '@/interfaces/ISearchResult';
import { IGene } from '@/interfaces/IGene';
import DiseaseHit from './DiseaseHit.vue';

export default Vue.extend({
  components: {
    DiseaseHit,
  },
  data() {
    return {
      gene: this.$props.entity,
      searchResults: null as IGene2DiseaseSearchResult[] | null,
    };
  },
  methods: {
    getApiUrl(): string {
      return `${this.$apiPrefix}/genes/${this.gene.entrez_id}/diseases/`;
    },
    getPathogenicityEvidenceUrl(cui: string): string {
      return `${this.$apiPrefix}/genes/${this.gene.entrez_id}/diseases/${cui}/pathogenicity/`;
    },
    getTreatabilityEvidenceUrl(cui: string): string {
      return `${this.$apiPrefix}/genes/${this.gene.entrez_id}/diseases/${cui}/treatability/`;
    },
  },
  async mounted() {
    const response = await axios.get(this.getApiUrl());
    this.searchResults = response.data;
  },
  props: {
    entity: Object as () => IGene,
  },
});
