












import Vue from 'vue';
import TopBar from './components/TopBar.vue';
import SgxFooter from './components/SgxFooter.vue';

export default Vue.extend({
  components: {
    TopBar,
    SgxFooter,
  },
});
