import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import styles from '@/assets/scss/exports.scss';
import Follow from '@/components/icons/Follow.vue';
import Treatability from '@/components/icons/Treatability.vue';
import Pathogenicity from '@/components/icons/Pathogenicity.vue';
import Trial from '@/components/icons/Trial.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: styles.pistachioDark,
        secondary: styles.greyText,
        accent: styles.pistachioLight,
        error: styles.red,
        info: styles.pistachio,
        success: styles.green,
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: 'fa',
    values: {
      follow_sgx: {
        component: Follow,
      },
      treatability_sgx: {
        component: Treatability,
      },
      pathogenicity_sgx: {
        component: Pathogenicity,
      },
      trial_sgx: {
        component: Trial,
      },
    },
  },
});
