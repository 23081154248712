import { IGeneSearchMatch } from '@/interfaces/IGene';
import SearchableEntity from './SearchableEntity';

export default class SearchableGene extends SearchableEntity {
  entityType = 'Gene';

  constructor(gsm: IGeneSearchMatch) {
    super(String(gsm.gene.entrez_id), gsm.matching_symbol, gsm);
  }

  get url(): string {
    return `/gene/${this.identifier}`;
  }
}
