







































































































import Vue from 'vue';
import axios from 'axios';

import { ITreatabilityEvidence, TreatabilityColors, ITreatabilityPublication } from '@/interfaces/ITreatability';
import { IGene } from '@/interfaces/IGene';
import { IDrug } from '@/interfaces/IDrug';
import MoreLess from './MoreLess.vue';

export default Vue.extend({
  components: {
    MoreLess,
  },
  data: () => ({
    colors: TreatabilityColors,
    evidenceList: null as ITreatabilityEvidence[] | null,
  }),
  methods: {
    getSourceUrl(evidence: ITreatabilityEvidence) {
      switch (evidence.source) {
        case 'CIViC':
          return this.getCivicUrl(evidence);
        case 'DGI':
          return this.getDgiUrl(evidence);
        case 'ChEMBL':
          return this.getChemblUrl(evidence);
        default:
          throw new Error();
      }
    },
    getCivicUrl(evidence: ITreatabilityEvidence) {
      return `https://civic.genome.wustl.edu/links/evidence_items/${evidence.source_id}/`;
    },
    getDgiUrl(evidence: ITreatabilityEvidence) {
      const gene = (evidence.gene as IGene);
      return `https://www.dgidb.org/genes/${gene.canonical_symbol}`;
    },
    getChemblUrl(evidence: ITreatabilityEvidence) {
      const drug = (evidence.drug as IDrug);
      return `https://www.ebi.ac.uk/chembl/compound_report_card/${drug.chembl_id}/`;
    },
    getCitLabel(citationType: string, citationId: string, idx: number) {
      if (citationType === 'DailyMed') {
        return `${idx + 1}`;
      }
      if (citationType === 'FDA' && !citationId.startsWith('label/')) {
        return `${idx + 1}`;
      }
      return citationId;
    },
    getCitationUrl(citationType: string, citationId: string) {
      switch (citationType) {
        case 'PubMed':
          return this.getPubmedUrl(citationId);
        case 'ASCO':
          return this.getAscoUrl(citationId);
        case 'FDA':
          return this.getFdaUrl(citationId);
        case 'ClinicalTrials':
          return this.getClinicalTrialsUrl(citationId);
        case 'ATC':
          return this.getAtcUrl(citationId);
        case 'DailyMed':
          return this.getDailyMedUrl(citationId);
        default:
          throw new Error();
      }
    },
    getAscoUrl(citationId: string) {
      return `https://meetinglibrary.asco.org/record/${citationId}/abstract/`;
    },
    getPubmedUrl(pmid: string) {
      return `https://pubmed.ncbi.nlm.nih.gov/${pmid}/`;
    },
    getFdaUrl(citationId: string) {
      if (citationId.startsWith('label/')) {
        return `https://www.accessdata.fda.gov/drugs/atfda_docs/${citationId}`;
      }
      return `https://api.fda.gov/drug/label.json?search=set_id:${citationId}`;
    },
    getClinicalTrialsUrl(citationId: string) {
      return `https://clinicaltrials.gov/show/${citationId}`;
    },
    getAtcUrl(citationId: string) {
      return `https://www.whocc.no/atc_ddd_index/?code=${citationId}`;
    },
    getDailyMedUrl(citationId: string) {
      return `https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=${citationId}`;
    },
    getEvidenceText(evidence: ITreatabilityEvidence): string {
      // TODO remove the check and default behavior: API now always provides text
      if (evidence.citation.text) {
        return evidence.citation.text;
      }
      return `${this.title} are documented as ${evidence.clinsig.replace('_', ' ')}`;
    },
    // FIXME
    formatCitation(publication: ITreatabilityPublication) {
      let authorYear;

      if (publication.first_author && publication.year) {
        authorYear = `${publication.first_author}, ${publication.year}. `;
      } else {
        authorYear = '';
      }
      return ''.concat(
        authorYear,
        `"${publication.title}" `,
        `<i>${publication.journal}</i>.`,
      );
    },
  },
  props: {
    title: String,
    evidenceUrl: String,
    icon: String,
  },
  async mounted() {
    if (!this.evidenceUrl) {
      this.evidenceList = [];
    } else {
      const response = await axios.get(this.evidenceUrl);
      this.evidenceList = response.data;
    }
  },
});
