import mixpanel from 'mixpanel-browser';

/* eslint-disable camelcase */
interface SgxMixpanelEventData {
  user_ip: string;
  seeq_api_instance: string;
  project_token: string;
}

interface SgxMixpanelBackendData {
  send_events: boolean;
  mixpanel_data: SgxMixpanelEventData;
}
export default class SgxMixpanel {
  defaultBackendData: SgxMixpanelBackendData;

  constructor(data: SgxMixpanelBackendData) {
    mixpanel.init(data.mixpanel_data.project_token);
    this.defaultBackendData = data;
    if (!data.send_events) {
      mixpanel.disable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackEvent(eventName: string, properties: { [id: string] : any; }): void {
    const defaultProperties = {
      ...this.defaultBackendData.mixpanel_data,
      app_name: 'seeq.web',
      domain: window.location.hostname,
    };

    const eventProperties = {
      ...defaultProperties,
      ...properties,
    };
    mixpanel.track(eventName, eventProperties);
  }
}
