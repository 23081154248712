






























































import Vue from 'vue';

import { IDrugSearchResult } from '@/interfaces/ISearchResult';
import RelationshipInstanceSummary from './RelationshipInstanceSummary.vue';
import TreatabilitySummary from './TreatabilitySummary.vue';

export default Vue.extend({
  components: {
    RelationshipInstanceSummary,
    TreatabilitySummary,
  },
  props: {
    searchResult: Object as () => IDrugSearchResult,
    entityName: String,
    pathogenicityEvidenceUrl: String,
    treatabilityEvidenceUrl: String,
  },
});

