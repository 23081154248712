





















































































import Vue from 'vue';
import axios from 'axios';

import { IPathogenicityEvidence, IPathogenicityPublication } from '@/interfaces/IPathogenicity';
import MoreLess from './MoreLess.vue';

export default Vue.extend({
  components: {
    MoreLess,
  },
  data: () => ({
    evidenceList: null as IPathogenicityEvidence[] | null,
  }),
  methods: {
    getSourceUrl(evidence: IPathogenicityEvidence) {
      switch (evidence.source) {
        case 'CIViC':
          return this.getCivicUrl(evidence.source_id);
        case 'ClinVar':
          return this.getClinvarUrl(evidence.source_id);
        default:
          throw new Error();
      }
    },
    // Duplicated from Condition Hit
    getPathogenicityColor(clinsig: string) {
      if (clinsig === 'possibly_pathogenic') {
        return 'orange';
      }
      return 'red';
    },
    getClinvarUrl(sourceId: string) {
      return `https://www.ncbi.nlm.nih.gov/clinvar/variation/${sourceId}`;
    },
    getCivicUrl(sourceId: string) {
      return `https://civic.genome.wustl.edu/links/evidence_items/${sourceId}`;
    },
    getPubmedUrl(pmid: number) {
      return `https://pubmed.ncbi.nlm.nih.gov/${pmid}`;
    },
    getEvidenceText(evidence: IPathogenicityEvidence): string {
      // TODO remove the check and default behavior: API now always provides text
      if (evidence.citation.text) {
        return evidence.citation.text;
      }
      return `${this.title} are documented as ${evidence.clinsig.replace('_', ' ')}`;
    },
    formatCitation(publication: IPathogenicityPublication) {
      let authorYear;

      if (publication.first_author && publication.year) {
        authorYear = `${publication.first_author}, ${publication.year}. `;
      } else {
        authorYear = '';
      }
      return ''.concat(
        authorYear,
        `"${publication.title}" `,
        `<i>${publication.journal}</i>.`,
      );
    },
  },
  props: {
    title: String,
    evidenceUrl: String,
    icon: String,
  },
  async mounted() {
    if (!this.evidenceUrl) {
      this.evidenceList = [];
    } else {
      const response = await axios.get(this.evidenceUrl);
      this.evidenceList = response.data;
    }
  },
});
