import DrugDiseaseHitList from '@/components/DrugDiseaseHitList.vue';
import DrugGeneHitList from '@/components/DrugGeneHitList.vue';
import JoinBeta from '@/components/JoinBeta.vue';

import Facet from '@/models/Facet';

export default [
  new Facet(
    'conditions',
    'Conditions',
    DrugDiseaseHitList,
  ),
  new Facet(
    'genes',
    'Genes',
    DrugGeneHitList,
  ),
  new Facet(
    'clinical-trials',
    'Clinical Trials',
    JoinBeta,
  ),
];
