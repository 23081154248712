import { ISearchableEntityData } from '@/interfaces/ISearchableEntityData';

export default abstract class SearchableEntity {
  identifier: string;
  label: string;
  entity: ISearchableEntityData;
  abstract entityType: string;
  abstract url: string;

  constructor(identifier: string, label: string, entity: ISearchableEntityData) {
    this.identifier = identifier;
    this.label = label;
    this.entity = entity;
  }

  // Identifier so we can properly distinguish each entity assures unique DOM ids.
  // For example, if I have gene id 3417 and disease id 3417, this helper assures uniqueness.
  get searchableEntityIdentifier(): string {
    return `${this.entityType}::${this.identifier}`;
  }
}
