





































import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  data: () => ({
    sourceVersions: {} as Record<string, string>,
  }),
  async created() {
    const response = await axios.get(`${this.$apiPrefix}/internal/versions`);
    this.sourceVersions = response.data;
  },
  metaInfo() {
    return {
      title: 'Source Versions',
      meta: [
        {
          name: 'description',
          content: 'A comprehensive list of sources and versions for the Seeq Bio '.concat(
            'genomic search engine and the SeeqVCF analysis tool.',
          ),
        },
      ],
    };
  },
});
