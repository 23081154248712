<template>
  <div>
    <v-row>
      <v-col cols=6 md=2 offset=3 offset-md=5>
        <v-img contain :src="require('@/assets/img/404.png')"/>
      </v-col>
    </v-row>
    <div class="mt-4 text-center">
      <h4> Page Not found ! </h4>
      <p> Double check to see if you have the correct address </p>
      <p> For a fresh start, go to the <router-link to="/"> home page </router-link></p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  metaInfo() {
    return {
      title: 'Not Found',
    };
  },
});
</script>
