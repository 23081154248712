






















































import Vue from 'vue';
import AutoComplete from '@/components/AutoComplete.vue';

export default Vue.extend({
  async created() {
    await this.$trackEvent('homepage_view');
  },
  components: {
    AutoComplete,
  },
  metaInfo() {
    return {
      title: 'seeq',
      meta: [
        {
          name: 'description',
          content: 'Seeq.bio is a free, intuitive search engine which makes a variety '.concat(
            'of genomic data, gene-drug, gene-disease, and variant interactions easy to find.',
          ),
        },
      ],
    };
  },
});
