
















import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    more: false,
  }),
  props: {
    fullText: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    cuttoffIdx(): number {
      const text = this.fullText as string;
      const minLen = 120;
      const maxLen = 400;

      for (let idx = 0; idx < text.length; idx += 1) {
        if (text[idx] === '.' && idx >= minLen) {
          return idx;
        }
        if (text[idx] === ' ' && idx >= maxLen) {
          return idx;
        }
      }
      return text.length - 1;
    },
    excerpt() {
      const index = this.cuttoffIdx as number;
      return this.fullText.slice(0, index + 1);
    },
    rest() {
      const index = this.cuttoffIdx as number;
      return this.fullText.slice(index + 1, this.fullText.length);
    },
  },
  methods: {
    toggleMoreLess() {
      this.$data.more = !this.$data.more;
    },
  },
});
