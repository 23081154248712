export default class Facet {
  name: string;
  label: string;
  component: Vue.Component;
  domClass: string;

  constructor(name: string, label: string, component: Vue.Component, domClass?: string) {
    this.name = name;
    this.label = label;
    this.component = component;
    this.domClass = domClass || '';
  }
}
