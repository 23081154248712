













import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters([
      'sidepanel/contentIdentifier',
    ]),
    resultClass() {
      let resultClass = 'result d-inline-block px-2';
      resultClass = this.selected ? resultClass += ' selected' : resultClass;
      resultClass = this.$props.iconColor === 'grey' ? resultClass += ' sgx-muted' : resultClass;

      return resultClass;
    },
    selected() {
      if (!this['sidepanel/contentIdentifier']) {
        return false;
      }
      return this['sidepanel/contentIdentifier'] === this.resultIdentifier;
    },
  },
  props: {
    evidenceUrl: String,
    evidenceComponent: Function,
    relationshipTitle: String,
    icon: String,
    iconColor: String,
    resultIdentifier: String,
    summaryText: String,
  },
  methods: {
    selectSummary() {
      if (this.evidenceUrl) {
        // Revisit this event. Having trackEvent live in the sidepanel store
        // adds a lot of complexity to the store. All the pieces should be there
        this.$trackEvent('open-sidepanel', {
          sidepanel_component: 'evidence',
          relationship_title: this.$props.relationshipTitle,
        });
        this.$store.commit('sidepanel/open', {
          component: this.$props.evidenceComponent,
          props: {
            evidenceUrl: this.$props.evidenceUrl,
            title: this.$props.relationshipTitle,
            icon: this.$props.icon,
          },
          contentIdentifier: this.$props.resultIdentifier,
        });
      }
    },
  },
});
