














import Vue from 'vue';

export default Vue.extend({
  props: {
    pfam_id: String,
    pfam_name: String,
    name: String,
    summary: String,

    aa_start: Number,
    aa_end: Number,
  },
});
