import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Faq from '../views/Faq.vue';
import Signup from '../views/Signup.vue';
import Signin from '../views/Signin.vue';
import AccountEdit from '../views/AccountEdit.vue';
import SourceVersions from '../views/SourceVersions.vue';
import NotFound from '../views/NotFound.vue';
import VcfPricing from '../views/VcfPricing.vue';
import AboutSeeqVcf from '../views/AboutSeeqVcf.vue';

import Facet from '@/models/Facet';
import GeneFacets from '@/facets/GeneFacets';
import DiseaseFacets from '@/facets/DiseaseFacets';
import DrugFacets from '@/facets/DrugFacets';
import VariantFacets from '@/facets/VariantFacets';

Vue.use(VueRouter);

const geneTabRegex = GeneFacets.map((f: Facet) => f.name).join('|');
const diseaseTabRegex = DiseaseFacets.map((f: Facet) => f.name).join('|');
const drugTabRegex = DrugFacets.map((f: Facet) => f.name).join('|');
const variantTabRegex = VariantFacets.map((f: Facet) => f.name).join('|');

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
  },
  // {
  //   path: '/signup',
  //   name: 'Signup',
  //   component: Signup,
  // },
  // {
  //   path: '/signin',
  //   name: 'Signin',
  //   component: Signin,
  // },
  // {
  //   path: '/account-edit',
  //   name: 'AccountEdit',
  //   component: AccountEdit,
  // },
  {
    path: '/source-versions',
    name: 'SourceVersions',
    component: SourceVersions,
  },
  {
    path: `/gene/:id/:tab(${geneTabRegex})?`,
    name: 'GeneSearchResults',
    // route level code-splitting
    // this generates a separate chunk ([route].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "gene-search-results" */ '../views/GeneSearchResults.vue'),
    meta: { searchResults: true },
  },
  {
    path: `/condition/:id/:tab(${diseaseTabRegex})?`,
    name: 'DiseaseSearchResults',
    component: () => import(/* webpackChunkName: "disease-search-results" */ '../views/DiseaseSearchResults.vue'),
    meta: { searchResults: true },
  },
  {
    path: `/drug/:id/:tab(${drugTabRegex})?`,
    name: 'DrugSearchResults',
    component: () => import(/* webpackChunkName: "drug-search-results" */ '../views/DrugSearchResults.vue'),
    meta: { searchResults: true },
  },
  {
    path: `/variant/:id/:tab(${variantTabRegex})?`,
    name: 'VariantSearchResults',
    component: () => import(/* webpackChunkName: "variant-search-results" */ '../views/VariantSearchResults.vue'),
    meta: { searchResults: true },
  },
  {
    path: '/join-beta',
    name: 'JoinBeta',
    component: () => import(/* webpackChunkName: "join-beta" */ '../views/JoinBeta.vue'),
  },
  {
    path: '/join',
    name: 'JoinSeeq',
    component: () => import(/* webpackChunkName: "join" */ '../views/JoinSeeq.vue'),
  },
  // Uncomment these URLs to enable VCF sandbox endpoints
  {
    path: '/seeq-vcf',
    name: 'SeeqVcf',
    component: () => import(/* webpackChunkName: "seeq-vcf" */ '../views/VcfSandbox.vue'),
  },
  {
    path: '/seeq-vcf/about',
    name: 'AboutSeeqVcf',
    component: AboutSeeqVcf,
  },
  {
    path: '/seeq-vcf/pricing',
    name: 'VcfPricing',
    component: VcfPricing,
  },
  { path: '*', name: 'NotFound', component: NotFound },
];

const router = new VueRouter({
  mode: 'history',
  // Use a prefix so incoming request can be properly routed to the vue app
  // or the django app. Value here must match the one specified in urls.py
  base: '/app/',
  routes,
});

export default router;
