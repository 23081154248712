<template>
  <div>
    <div class="text-center">
      <h1 style="font-family: Montserrat;"> SeeqVCF Plans </h1>
    </div>
    <v-row class="mt-10">
      <v-col offset=1 cols=10 offset-sm=1 sm=5 offset-lg=2 lg=4>
        <v-card class="py-4 px-8">
          <div class="text-center">
            <h3 class="pb-2 pt-4"> Lite </h3>
            <h1 class="pb-2"> Free </h1>
            <div class="primary--text font-weight-bold">SeeqVCF is free for everyone!</div>
            <hr class="mx-8 mb-4 mt-7">
          </div>
          <div class="lite-features ml-8">
            <div><v-icon x-small>fa-check</v-icon> Up to 10 genes per VCF</div>
            <div><v-icon x-small>fa-check</v-icon> Access to seeq.bio knowledge base</div>
            <div><v-icon x-small>fa-check</v-icon> Daytime customer support</div>
            <div><v-icon x-small>fa-check</v-icon> Access to gene browser at seeq.bio</div>
          </div>
        </v-card>
      </v-col>
      <v-col offset=1 cols=10 offset-sm=0 sm=5 offset-lg=0 lg=4>
        <v-card class="py-4 px-8 white--text" color="#333">
          <div class="text-center">
            <h3 class="pt-4 pb-2"> Enterprise </h3>
            <h1 class="pb-2"> Get a quote </h1>
            <a href="https://hn3s1xj6ott.typeform.com/to/T9xL889A">
              <v-btn
                class="white--text"
                color="blue"
                depressed
              >
                Contact us
              </v-btn>
            </a>
            <hr class="mx-8 my-4">
          </div>
          <div class="enterprise-features ml-8">
            <div><v-icon x-small>fa-check</v-icon>
              Unlimited VCF file size and Gene Panels
            </div>
            <div><v-icon x-small>fa-check</v-icon>
              VCFs based on GRCh37 and other assemblies
            </div>
            <div><v-icon x-small>fa-check</v-icon>
              Collaborate in teams
            </div>
            <div><v-icon x-small>fa-check</v-icon>
              HIPAA and PIPEDA compliant cloud storage
            </div>
            <div><v-icon x-small>fa-check</v-icon>
              Save and pin results
            </div>
            <div><v-icon x-small>fa-check</v-icon>
              24/7 VIP Customer Support
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  created() {
    this.$trackEvent('vcf-pricing', {});
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: 'Upgrade to SeeqVCF Enterprise to unlock: no file size limits, '.concat(
            'saved results, 24/7 customer support and more.',
          ),
        },
      ],
      title: 'SeeqVCF Pricing',
    };
  },
});
</script>

<style lang="scss" scoped>
  .blue-border.theme--light.v-btn.v-btn--outlined.v-btn--text {
    border-color: #2196f3;
    border-width: 2px;
    white-space: normal;
  }
  .v-card {
    height: 500px;
  }
  .lite-features, .enterprise-features {
    .v-icon {
      padding-right: 1em;
      padding-bottom: 0.2em;
    }
  }

  .enterprise-features {
    .v-icon {
      color: #ffd700;
    }
  }
</style>
