


































































import Vue from 'vue';
import AccountWidget from '@/components/AccountWidget.vue';
import AutoComplete from '@/components/AutoComplete.vue';

export default Vue.extend({
  components: {
    AccountWidget,
    AutoComplete,
  },
  computed: {
    logoSrc() {
      if (['sm', 'md'].includes(this.$vuetify.breakpoint.name)) {
        return 'logo.png';
      }
      return 'logo-with-text.png';
    },
  },
  props: {
    searchResult: Boolean,
  },
});
