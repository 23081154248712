/* eslint-disable */

import HorizontalZoomPlot from './zoom.js';
import DATA from './data.js';
// import * as d3 from "https://cdn.skypack.dev/d3@7"; // browser use
import * as d3 from 'd3'; // webpack use
import { tip as d3tip } from "d3-v6-tip";

export default class GeneBrowser extends HorizontalZoomPlot {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.maxEvidenceCount = Math.max(...this.data.pathogenicity.map(d => d.summary.evidence_count));

    this.focusBp = props.focusBp || null;

    this.domainClickCallback = props.domainClickCallback;
    this.lollipopClickCallback = props.lollipopClickCallback;

    this.opts.rna = {
      bg:  '#eef3fd',
      fg:  '#666',
      height: 30,
    }

    this.opts.exon = {
      bg:  '#f5f5f5',
      fg:  '#666',
      height: 10,
    };

    this.opts.protein = {
      bg:  '#c8c0f8',
      fg:  '#666',
      height: 50,
    }

    this.opts.domain = {
      bg:  '#f8e4c0',
      fg:  '#666',
    }

    this.opts.lollipops = {
      colors: {
        // FIXME duplicated colors from search results, integrate with scss variables
        'possibly_pathogenic': '#fb933d',
        'pathogenic': '#c33d3d',
      }
    };

    this.opts.trackMargin = 10;
  }

  render() {
    super.render();


    this.createAxisLabel();


    this.createExons();
    this.createExonBoundaries();
    this.createExonRanks();

    this.createRNA();
    this.createRNALabel();
    this.createNucleotides();

    this.createProtein();
    this.createCodonBoundaries();
    this.createAminoAcids();

    this.tooltip = d3tip().attr('class', 'd3-tip').html((event, d) => {
        return `<p class="lollipop-tooltip"> ${d.summary.text}</p>`;
    });
    this.svg.call(this.tooltip);

    this.createLollipops();

    this.createDomains();
    this.createProteinLabel(); // after domains

    this.setZoomAwareAttrs(this.xScale);

    this.svg
        .on('mouseover', () => {
          this.active = true;
        })
        .on('mouseout', () => {
          this.active = false;
        });

    d3.select('body').on('keydown', (event) => {
      if (!this.active) return;

      const [zoomStartBp, zoomEndBp] = this.zoomXScale.domain();
      let newMidPointBp;

      switch(event.key) {
      case 'ArrowUp':
        this.zoom.scaleBy(this.svg, 3/2);
        break;
      case 'ArrowDown':
        this.zoom.scaleBy(this.svg, 2/3);
        break;
      case 'ArrowRight':
        newMidPointBp = (zoomStartBp + zoomEndBp) / 2 + (zoomEndBp - zoomStartBp) * 0.1;
        this.zoom.translateTo(this.svg, this.xScale(newMidPointBp), 0);
        break;
      case 'ArrowLeft':
        newMidPointBp = (zoomStartBp + zoomEndBp) / 2 - (zoomEndBp - zoomStartBp) * 0.1;
        this.zoom.translateTo(this.svg, this.xScale(newMidPointBp), 0);
        break;
      }
    });
  }

  zoomOnFirst() {
    // a UX utility for zooming in on the tallest lollipop (assumed to be first)
    const bp = this.aaToBp(this.data.pathogenicity[0].aa_pos);
    this.zoom.scaleBy(this.svg, 80);
    this.zoom.translateTo(this.svg, this.xScale(bp), 0);
  }

  zoomOut() {
    // the opposite of zoomOnFirst
    this.zoom.scaleBy(this.svg, 1e-3);
  }

  bpToX(bp) {
    return bp;
  }

  xToBp(x) {
    return x;
  }

  aaToBp(aa) {
    return this.data.transcript.fcn_cdna_pos + (aa - 1) * 3;
  }

  bpToAA(bp) {
    if (bp < this.data.transcript.fcn_cdna_pos) {
        return null;
    }
    return Math.ceil((bp - this.data.transcript.fcn_cdna_pos) / 3);
  }

  aaToX(aa) {
    return this.bpToX(this.aaToBp(aa));
  }

  get xDomain() {
    return [1, this.data.transcript.cdna.length].map(x => this.bpToX(x));
  }

  trackY(track) {
    const m =  this.opts.trackMargin;
    switch(track) {
    case 'exon-rank':
      return this.opts.height - m;
    case 'exon':
      return this.opts.height - this.opts.exon.height - 2*m;
    case 'rna':
      return this.opts.height - this.opts.exon.height - this.opts.rna.height - 3*m;
    case 'nucleotides':
      return this.opts.height - this.opts.exon.height - this.opts.rna.height / 2 - 3*m;
    case 'protein':
      return this.opts.height - this.opts.exon.height - this.opts.rna.height - this.opts.protein.height - 4*m;
    case 'amino-acid':
      return this.opts.height - this.opts.exon.height - this.opts.rna.height - this.opts.protein.height / 2 - 4*m;
    case 'domain':
      return this.opts.height - this.opts.exon.height - this.opts.rna.height - this.opts.protein.height - 5*m;
    default:
      throw new Error(`Invalid track: ${track}`);
    }
  }

  zoomXbp(bp) {
    // DX help: our underlying scales are not in "nucleotides", they are in
    // "nucleotides + spacers". Yet we want to be able to speak nucleotides in
    // our code and not repeat the conversion logic between nucleotides and the
    // logical X. Use this in zoom-aware coordinate definitions.
    return this.zoomXScale(this.bpToX(bp));
  }

  zoomXaa(aa) {
    return this.zoomXScale(this.aaToX(aa));
  }

  createXAxis() {
    super.createXAxis();
    // UX help: the actual tick values are in logical X to match the underlying
    // xScale and zoomXScale. But render them as nucleotides because that's what
    // the user needs to see.
    this.xAxis.tickFormat(x => {
        const bp = Math.floor(this.xToBp(x));
        const aa = this.bpToAA(bp);
        return aa ? `${bp} (${aa})` : `${bp}`;
    });

    // note: tick values get updated on every zoom event.
    this.xAxis.tickValues(this.xDomain);
  }

  createAxisLabel() {
    this.svg.append('g') // axis label, just the "bp"
      .append('text')
      .attr('x', this.opts.width - this.opts.margin.right * 0.7)
      .attr('y', 16)
      .style('font-size', '11px')
      .text('bp (aa)');
  }

  createRNA() {
    this.rna = this.root.append('rect')
      .attr('x', this.bpToX(0))
      .attr('y', this.trackY('rna'))
      .attr('height', this.opts.rna.height)
      .attr('width', this.bpToX(this.data.transcript.cdna.length) - this.bpToX(0))
      .style('fill', this.opts.rna.bg);
  }

  createRNALabel() {
    this.rnaLabel = this.root
      .append('g')
      .append('text')
      .text(`${this.data.gene.canonical_symbol} cDNA`)
      .attr('y', this.trackY('rna') + this.opts.rna.height / 2 + 5)
      .style('fill', this.opts.protein.fg);
  }

  createNucleotides() {
    this.nucleotides = this.root.selectAll('text.nucleotide')
      .data(Array.from(this.data.transcript.cdna).map((nucl, idx) => { return {nucl: nucl, pos: idx + 1}}))
      .enter()
      .append('text')
      .attr('class', 'nucleotide')
      .text(loc => loc.nucl)
      .style('color', this.opts.rna.fg)
      // note: x, y, and font-size are zoom aware
  }

  createExons() {
    this.exons = this.root.selectAll('rect.exon-rect')
      .data(this.data.exons)
      .enter()
      .append('rect')
      .attr('class', 'exon-rect')
      .attr('fill', this.opts.exon.bg)
      .attr('y', this.trackY('exon'))
      .attr('height', this.opts.exon.height);
      // x and width are zoom-aware
  }

  createExonBoundaries() {
    this.exonBoundaries = this.root.selectAll('line.exon-boundary')
      .data(this.data.exons)
      .enter()
      .append('line')
      .attr('class', 'exon-boundary')
      .attr('y1', this.trackY('exon') - 5)
      .attr('y2', this.trackY('exon') + 5 + this.opts.trackMargin)
      .style('stroke', this.opts.exon.fg)
      // .style('stroke-width', 5)
      .style('stroke-dasharray', '2,2')
      // x is zoom-aware
  }

  createExonRanks() {
    this.exonRanks = this.root.selectAll('text.exon-rank')
      .data(this.data.exons)
      .enter()
      .append('text')
      .attr('class', 'exon-rank')
      .attr('y', this.trackY('exon-rank') + 5)
      .style('fill', this.opts.exon.fg)
      .text(exon => `E${exon.rank}`);
      // note: x and font-size are zoom aware
  }

  createProtein() {
    this.protein = this.root
      .append('rect')
      .attr('class', 'protein')
      .attr('y', this.trackY('protein'))
      .attr('height', this.opts.protein.height)
      .style('fill', this.opts.protein.bg)
      .style('border-radius', '10px');
      // x and width are zoom aware
  }

  createProteinLabel() {
    this.proteinLabel = this.root
      .append('g')
      .append('text')
      .text(`${this.data.gene.canonical_symbol} protein`)
      .attr('y', this.trackY('protein') + this.opts.protein.height / 2 + 5)
      .style('fill', this.opts.protein.fg);
  }

  createCodonBoundaries() {
    this.codonBoundaries = this.root.selectAll('line.codon-boundary')
      .data([...Array(this.data.transcript.peptide.length).keys()].map(idx => this.data.transcript.fcn_cdna_pos + 3 * idx).slice(1))
      .enter()
      .append('line')
      .attr('class', 'codon-boundary')
      .attr('y1', this.trackY('protein') + 0.25 * this.opts.protein.height)
      .attr('y2', this.trackY('protein') + 0.75 * this.opts.protein.height)
      .style('stroke', this.opts.protein.fg)
      .style('stroke-width', .5)
      .style('stroke-dasharray', '4,5')
      // .style('stroke', '1px dotted #444');
      // x1, x2 are zoom-aware
  }

  createAminoAcids() {
    this.aminoacids = this.root.selectAll('text.amino-acid-text')
      .data(Array.from(this.data.transcript.peptide).map((aa, idx) => { return {aa: aa, pos: idx + 1}}))
      .enter()
      .append('text')
      .attr('class', 'amino-acid-text')
      .text(loc => loc.aa)
      .style('fill', this.opts.protein.fg)
      // note: x, y, and font-size are zoom aware
  }

  createDomains() {
    this.domains = this.root.selectAll('rect.domain-rect')
      .data(this.data.domains)
      .enter()
      .append('rect')
      .attr('class', 'domain-rect')
      .attr('y', this.trackY('protein') + 5)
      .attr('height', this.opts.protein.height - 10)
      .attr('fill', '#ffffff00')
      .style('stroke', this.opts.domain.bg)
      .style('stroke-width', 3)
      .on('click', (event, domain) => this.domainClickCallback(event, domain))
      // x and width are zoom-aware
  }

  _lollipopHeight(evidenceCount) {
    const availHeight = this.trackY('protein') - 2 * this._lollipopRadius(evidenceCount);
    return availHeight * 0.1 + (availHeight * 0.8) * evidenceCount / this.maxEvidenceCount;
  }

  _lollipopRadius(evidenceCount) {
    return 5 + 10 * evidenceCount / this.maxEvidenceCount;
  }

  createLollipops() {
    this.lollipops = this.root.selectAll('g.lollipop')
        .data(this.data.pathogenicity)
        .enter()
        .append('g')
        .attr('class', 'lollipop')
        .on('click', (event, d) => {
            this.lollipopClickCallback(event, {
                geneId: this.data.gene.entrez_id,
                aaPos: d.aa_pos,
                title: `${this.data.gene.canonical_symbol} variants at amino acid ${d.aa_pos}`,
            });
            this.removeActiveLollipop();
            d3.select(event.currentTarget).attr('class', 'lollipop active');
        });

    this.lollipopLines = this.lollipops.append('line')
        .attr('y1', this.trackY('protein'))
        .attr('y2', d => {
            return this.trackY('protein')
                   - this._lollipopHeight(d.summary.evidence_count)
                   + this._lollipopRadius(d.summary.evidence_count);
        })
        .attr('stroke', '#aaa')
        .attr('stroke-width', 2);
        // x1 and x2 is zoom-aware

    this.lollipopCircles = this.lollipops.append('circle')
        .attr('cy', d => this.trackY('protein') - this._lollipopHeight(d.summary.evidence_count))
        .attr('r',  d => this._lollipopRadius(d.summary.evidence_count))
        .attr('fill', d => this.opts.lollipops.colors[d.summary.max_clinsig])
        .style('opacity', 0.7)
        .attr('cursor', 'pointer')
        .on('mouseover', this.tooltip.show)
        .on('mouseout', this.tooltip.hide)
        // cx is zoom-aware
  }

  removeActiveLollipop() {
    d3.selectAll('g.lollipop').attr('class', 'lollipop');
  }

  _midRangeBp(cdna_start, cdna_end) {
    // TODO clean up and rename
    // UX issues this tries to solve:
    //  1. if we just pin down the actual cdna midpoint, in some zoom
    //     levels its impossible to see the midrange (eg cant figure out which exon
    //     I'm on).
    //  2. in the last two scenarios we could either pin the "midrange" to the
    //     cdna start or end but that would make the positions jump
    //     discontinuously as we move left and right.
    //
    // UX issue this causes: it feels like dragging left and right distorts the
    // horizontal axis...
    const [zoomStartBp, zoomEndBp] = this.zoomXScale.domain().map(x => this.xToBp(x));

    if (cdna_end < zoomStartBp || cdna_start > zoomEndBp) {
      return zoomEndBp + 1000; // don't show it
    } else if (cdna_start >= zoomStartBp && cdna_end <= zoomEndBp) {
      // <------------- zoom --------------->
      //       <-----  range ----->
      return (cdna_start + cdna_end) / 2 - 5;
    } else if (cdna_start < zoomStartBp && cdna_end > zoomEndBp) {
      //       <------- zoom ----->
      // <-----------  range ------------>
      return (zoomStartBp + zoomEndBp) / 2;
    } else if (cdna_start > zoomStartBp && cdna_end > zoomEndBp) {
      // <--------- zoom --------->
      //       <-------- range ------------>
      // range start is visible, range end is not
      return (cdna_start + zoomEndBp) / 2;
    } else if (cdna_start < zoomStartBp && cdna_end < zoomEndBp) {
      //        <--------- zoom --------->
      // <-------- range ---------->
      // range end is visible, range start is not
      return (zoomStartBp + cdna_end) / 2;
    } else {
      throw new Error('coordinate arithmetic bug!');
    }
  }

  setZoomAwareAttrs(xScale) {
    // this function is called both in original render and in subsequent onZoom
    this.xAxis.tickValues(xScale.domain());

    const delta = this.zoomXbp(1) - this.zoomXbp(0);
    const renderSequences = delta > 5;
    // heuristic: fontSize = 1.5 x delta
    const nuclfontSize = Math.min(this.opts.rna.height * 0.6, Math.floor(1.5 * delta));
    const nuclSeqY = this.trackY('nucleotides') + nuclfontSize / 3;
    this.nucleotides
      .attr('x', loc => this.zoomXbp(loc.pos))
      .attr('y', nuclSeqY)
      .style('font-size', renderSequences ? `${nuclfontSize}px` : '0px')

    // the labels placed on top of cDNA/protein sequences in low zoom level
    // (when sequences themselves are not rendered)
    const proteinMidRange = this._midRangeBp(this.data.transcript.fcn_cdna_pos, this.data.transcript.lcn_cdna_pos)
    const labelPos = this.zoomXbp(proteinMidRange) - this.data.transcript.ensp.length / delta;
    this.rnaLabel
      .attr('x', labelPos)
      .style('font-size', renderSequences ? '0px' : '15px');

    this.exons
      .attr('x', exon => this.zoomXbp(exon.fn_cdna_pos - 0.2))
      .attr('width', delta > 0.2 ? exon => this.zoomXbp(exon.ln_cdna_pos + 0.2) - this.zoomXbp(exon.fn_cdna_pos - 0.2): 0);

    this.exonBoundaries
      .attr('x1', exon => this.zoomXbp(exon.fn_cdna_pos - 0.2))
      .attr('x2', exon => this.zoomXbp(exon.fn_cdna_pos - 0.2))
      .attr('stroke-width', exon => (exon.rank > 1 && delta > 0.2) ? 1 : 0);

    this.exonRanks
      .attr('x', delta > 0.5 ? exon => this.zoomXbp(this._midRangeBp(exon.fn_cdna_pos, exon.ln_cdna_pos)) - 5: 0)
      .attr('font-size', delta > 0.5 ? `13px` : '0px');

    this.protein
      .attr('x', this.zoomXbp(this.data.transcript.fcn_cdna_pos))
      .attr('width', this.zoomXbp(this.data.transcript.fcn_cdna_pos + this.data.transcript.cds_length) - this.zoomXbp(this.data.transcript.fcn_cdna_pos))

    this.proteinLabel
      .attr('x', labelPos)
      .style('font-size', renderSequences ? '0px' : '15px');

    this.codonBoundaries
      .attr('x1', bp => this.zoomXbp(bp - 0.25))
      .attr('x2', bp => this.zoomXbp(bp - 0.25))
      .style('stroke-width', delta > 15 ? 0.5 : 0)

    const aaFontSize = Math.min(this.opts.protein.height * 0.6, Math.floor(2 * delta));
    const aaSeqY = this.trackY('amino-acid') + aaFontSize / 3;
    this.aminoacids
      .attr('x', loc => this.zoomXbp(loc.pos * 3 + this.data.transcript.fcn_cdna_pos - 2.05))
      .attr('y', aaSeqY)
      .style('font-size', renderSequences ? `${aaFontSize}px` : '0px');

    const domainPadding = delta;
    this.domains
      .attr('x', domain => this.zoomXaa(domain.aa_start) - domainPadding)
      .attr('width', domain => this.zoomXaa(domain.aa_end) - this.zoomXaa(domain.aa_start) + 2 * domainPadding);

    this.lollipopLines
        .attr('x1', d => this.zoomXbp(this.aaToBp(d.aa_pos) + 1.2))
        .attr('x2', d => this.zoomXbp(this.aaToBp(d.aa_pos) + 1.2));

    this.lollipopCircles
        .attr('cx', d => this.zoomXbp(this.aaToBp(d.aa_pos) + 1.2));
  }
}
