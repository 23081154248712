






























































import Vue from 'vue';
import axios from 'axios';

import { IGene } from '@/interfaces/IGene';
import GeneBrowser from '@/gene-browser/browser';
import DomainInfo from './DomainInfo.vue';
import PathogenicityEvidence from './PathogenicityEvidence.vue';

export default Vue.extend({
  async mounted() {
    let data;
    await Promise.all([
      axios.get(`${this.$apiPrefix}/genes/${this.entity.entrez_id}/skeleton`),
      axios.get(`${this.$apiPrefix}/genes/${this.entity.entrez_id}/positions/pathogenicity`),
    ]).then((responses) => {
      const [skeleton, pathogenicity] = responses;
      data = { ...skeleton.data, pathogenicity: pathogenicity.data };
    }).catch((reason) => {
      this.apiError = reason.response.data.detail;
    });

    if (this.apiError != null) {
      return;
    }

    this.browser = new GeneBrowser({
      id: 'gene-browser',
      data,
      domainClickCallback: this.domainClickCallback.bind(this),
      lollipopClickCallback: this.lollipopClickCallback.bind(this),
    });
    this.browser.render();
  },
  props: {
    // FIXME entity may be a variant! see computed properties.
    entity: Object as () => IGene,
  },
  computed: {
    gene_symbol() {
      // FIXME: Gene Browser should accept gene or variant
      // eslint-disable-next-line
      return this.entity.canonical_symbol || (this.entity as any).gene_symbol;
    },
    hiddenClass() {
      return this.menuChanging ? 'd-none' : '';
    },
  },
  data: () => ({
    apiError: null as null | string,
    browser: null as null | GeneBrowser,
    popoverComponent: null as Vue.Component | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    popoverProps: {} as any,
    menu: false,
    menuX: 0,
    menuY: 0,
    // The following values are rendering helpers, before tying this value to
    // component props,see big comment block in `updated` lifecycle method.
    menuChanging: false,
    tempMenuX: 0,
    tempMenuY: 0,
  }),
  methods: {
    activatePopover(x: number, y: number) {
      this.tempMenuX = x;
      this.tempMenuY = y;
      this.menuChanging = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    domainClickCallback(event: PointerEvent, args: any) {
      this.activatePopover(event.x, event.y);
      this.popoverComponent = DomainInfo;
      this.popoverProps = args;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lollipopClickCallback(event: PointerEvent, args: any) {
      this.$store.commit('sidepanel/open', {
        component: PathogenicityEvidence,
        props: {
          evidenceUrl: `${this.$apiPrefix}/genes/${args.geneId}/positions/${args.aaPos}/pathogenicity/`,
          title: args.title,
          icon: '$pathogenicity_sgx',
        },
        contentIdentifier: `genome-browser-${args.aaPos}`,
      });
    },
    zoomIn() {
      (this.browser as GeneBrowser).zoomOnFirst();
    },
    zoomOut() {
      (this.browser as GeneBrowser).zoomOut();
    },
  },
  watch: {
    // eslint-disable-next-line
    '$store.state.sidepanel.isOpen'() {
      if (!this.$store.state.sidepanel.isOpen) {
        (this.browser as GeneBrowser).removeActiveLollipop();
      }
    },
  },
  /*
   * This code block is required to assure smooth transitions in the UI. We have
   * several modules interacting with the browser and the timing can be slightly
   * off. The following workaround is robust. However, there are a lot of moving
   * pieces. Here is a desription of these events:
   * When we click a tooltip, there are two states we can be in:
   *
   * 1. No open tooltips (life is good, no work needed.)
   * 2. Another tooltip is already open (We are dealing with an additional state).
   *
   * If we are in state 2), this is the timing of events upon a user clicking a
   * lollipop:
   * 1. The event handler in d3 code is triggered. `lollipopClickCallback` is
   *    executed.
   * 2. Our v-menu outside click event handler is triggers, open
   *    tooltip is going to be closed.
   *
   * In the above sequence, our v-menu component is not ready to render the new
   * tooltip in 1), since it must close the existing tooltip first. Here is the
   * suggested workaround. In the steps below, the `updated` function is followed
   * every time.
   *
   * 1. Event handler in d3 code is triggered.
   *    a. Store click position in values which
   *    are not associated to **any** `v-menu` props. If we did this, we would
   *    rerender the `v-menu` component, we are not ready for that yet.
   *    b. Hide the `v-menu` component. This allows us to show the tooltip once
   *       we are ready.
   * 2. `v-menu` closes itself.
   * 3. `v-menu` is closed, we are still in the process of chaning the popover.
   *    `v-menu` is now ready to show the new content, we can unhide it.
   */
  updated() {
    if (this.menuChanging && !this.menu) {
      this.menu = true;
      this.menuX = this.tempMenuX;
      this.menuY = this.tempMenuY;
      this.menuChanging = false;
    }
  },
});
